import React, { useState } from "react";
import { toast } from "react-toastify";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

const ArounderRegistration = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    region: "",
    l_name: "",
    email: "",
    gender: "",
    refcode: "",
    bank_id: "",
    referer: "",
    company_name: "",
    company_email: "",
    country: "",
    f_name: "",
    iban: "",
    national_id: "",
    country_code: "",
    tel: "",
    interests: [],
    understandInstructions: false,
    acceptTerms: false,
    acceptPolicy: false,
    iban_image: null,
    national_id_image: null,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(false);

  const fieldAliases = {
    region: "Region",
    l_name: "Last Name",
    email: "Email",
    refcode: "Referral Code",
    bank_id: "Bank Name",
    referer: "Referer",
    company_name: "Company Name",
    company_email: "Company Email",
    country: "Country",
    f_name: "First Name",
    iban: "IBAN",
    national_id: "National ID",
    country_code: "Country Code",
    tel: "Telephone",
    interests: "Interests",
    understandInstructions: "Understanding Instructions",
    acceptTerms: "Accept Terms",
    acceptPolicy: "Privacy Policy",
    iban_image: "IBAN Image",
    national_id_image: "National ID Image",
  };

  const nextStep = () => {
    const missingFields = validateFormData(step);
    if (missingFields.length > 0) {
      setError(
        `Please fill in the required fields: ${missingFields
          .map((field) => fieldAliases[field] || field)
          .join(", ")}`
      );
    } else {
      setError(null);
      setStep(step + 1);
    }
  };

  const prevStep = () => setStep(step - 1);

  const validateFormData = (currentStep) => {
    const requiredFieldsPerStep = {
      1: ["country", "region"],
      2: [],
      3: ["f_name", "l_name", "email","gender", "country_code", "tel"],
      4: [],
      5: [],
      6: ["bank_id", "iban", "national_id"],
    };

    const requiredFields = requiredFieldsPerStep[currentStep] || [];
    const missingFields = requiredFields.filter((field) => !formData[field]);
    return missingFields;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const arounder_data = {
      f_name: formData.f_name || "",
      l_name: formData.l_name || "",
      email: formData.email || "",
      gender: formData.gender || "",
      country: formData.country || "",
      country_code: formData.country_code || "",
      tel: formData.tel || "",
      region: formData.region || "",
      national_id: formData.national_id || "",
      refcode: formData.refcode || "",
      referer: formData.referer || "",
      company_name: formData.company_name || "",
      company_email: formData.company_email || "",
      bank_id: formData.bank_id || "",
      iban: formData.iban || "",
      interests: formData.interests || [],
      status: "inactive",
      verified: false,
    };

    const missingFields = validateFormData(step);
    if (missingFields.length > 0) {
      setError(
        `Please fill in the required fields: ${missingFields.join(", ")}`
      );
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "https://us-central1-aroundu-app.cloudfunctions.net/v1public/arounder/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(arounder_data),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      }

      const uid = data.data.uid;

      const documentData = new FormData();
      documentData.append("userId", uid);
      if (formData.iban_image) {
        documentData.append(
          "bank_proof",
          formData.iban_image,
          formData.iban_image.name
        );
      }
      if (formData.national_id_image) {
        documentData.append(
          "identity_proof",
          formData.national_id_image,
          formData.national_id_image.name
        );
      }

      const response2 = await fetch(
        "https://hook.us1.make.com/65vvdfwtb99n5fv7d3yqvaijbpa4jmpe",
        {
          method: "POST",
          body: documentData,
        }
      );

      const data2 = await response2.json();
      if (!response2.ok) {
        throw new Error(
          data2.message || "Something went wrong while uploading documents"
        );
      }

      toast.success("Arounder registered and document uploaded successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      setTimeout(() => {
        window.location.href = "/home";
      }, 2000);
    } catch (error) {
      setError(error.message || "An error occurred while submitting the form.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 formData={formData} setFormData={setFormData} />;
      case 2:
        return <Step2 formData={formData} setFormData={setFormData} />;
      case 3:
        return <Step3 formData={formData} setFormData={setFormData} isError={isError} setIsError={setIsError} />;
      case 4:
        return <Step4 formData={formData} setFormData={setFormData} />;
      case 5:
        return <Step5 formData={formData} setFormData={setFormData} />;
      case 6:
        return <Step6 formData={formData} setFormData={setFormData} isError={isError} setIsError={setIsError} />;
      default:
        return null;
    }
  };

  const buttonStyle = {
    border: "none",
    backgroundColor: "#00ccbb",
    color: "#fff",
    cursor: "pointer",
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#c0c0c0",
    cursor: "not-allowed",
  };

  return (
    <section className="contact-wrapper">
      <div className="faq-image">
        <div className="image-overlay"></div>
        <div className="faq-section-one">
          <h1>{t("arounder_registration")}</h1>

          <p>
            <a href="/contact" style={{ color: "#fff" }}>
              {t("contact_text")}
            </a>
          </p>
        </div>
      </div>
      <div className="arounder-registration-card">
        <form onSubmit={handleSubmit}>
          {renderStep()}
          <div style={{ display: "flex", marginTop: "2rem" }}>
            {step > 1 && (
              <button
                className="back-btn"
                type="button"
                onClick={prevStep}
                disabled={loading}
              >
                {t("back")}
              </button>
            )}
            {step < 6 && (
              <button
                type="button"
                onClick={nextStep}
                className="continue-btn"
                disabled={
                  loading ||
                  (step === 2 &&
                    (!formData.understandInstructions ||
                      !formData.acceptTerms ||
                      !formData.acceptPolicy)) ||
                  (step === 3 &&
                    (!formData.f_name ||
                      !formData.l_name ||
                      !formData.email ||
                      !formData.country_code ||
                      !formData.tel)) ||
                  isError ||
                  (step === 5 && formData.interests.length < 2)
                }
                style={
                  loading ||
                  (step === 2 &&
                    (!formData.understandInstructions ||
                      !formData.acceptTerms ||
                      !formData.acceptPolicy)) ||
                  (step === 3 &&
                    (!formData.f_name ||
                      !formData.l_name ||
                      !formData.email ||
                      !formData.country_code ||
                      !formData.tel ||
                      isError)) ||
                  (step === 5 && formData.interests.length < 2)
                    ? disabledButtonStyle
                    : buttonStyle
                }
              >
                {t("continue")}
              </button>
            )}
            {step === 6 && (
              <button
                className="continue-btn"
                type="submit"
                disabled={
                  loading ||
                  (step === 6 &&
                    (!formData.iban ||
                      !formData.iban_image ||
                      !formData.bank_id ||
                      !formData.national_id ||
                      !formData.national_id_image ||
                      isError))
                }
                style={
                  loading ||
                  (step === 6 &&
                    (!formData.iban ||
                      !formData.iban_image ||
                      !formData.bank_id ||
                      !formData.national_id ||
                      !formData.national_id_image ||
                      isError))
                    ? disabledButtonStyle
                    : buttonStyle
                }
              >
                {loading ? t("submitting") : t("submit")}
              </button>
            )}
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
      </div>

      <Footer />
    </section>
  );
};

export default ArounderRegistration;
